.container {
    position: relative;
}

.container span {
    position: absolute;
    right: 60px;
    top: calc(50% - 5px);
    cursor: pointer;
}

.container input {
    padding-right: 50px;
}