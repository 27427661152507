.App {
    text-align: center;
    background-color: #124e68;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #238bb8;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.background {
    min-height: 100vh;
    padding: 50px;
    position: relative;
}

.content-card {
    background-color: white;
    position: relative;
    width: 100%;
    margin: auto;
    min-height: 90vh;
    border-radius: 10px;
    padding-bottom: 50px;
    position: relative;
    box-shadow: 0px 17px 32px #00000038, 0px 17px 32px #00000038,
    0px 17px 32px #00000038, 0px 17px 32px #00000038;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.rdt_TableHeader {
    display: none !important;
}

.rdt_TableCol {
    padding-left: 0px !important;
}

.viewSuper {
    margin-top: 45px !important;
    margin-left: 300px !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.align-left {
    text-align: left;
}

.App-link {
    color: #61dafb;
}

.students-icons {
    font-size: 20px;
    vertical-align: bottom;
    color: #1890ff;
    cursor: pointer;
}

.ant-checkbox-wrapper {
    margin: 25px 0px 0px !important;
    display: flex !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.card {
    border: unset;
}

.ant-picker {
    width: 90%;
}

.rdt_TableCol,
.rdt_TableCell {
    -webkit-justify-content: unset !important;
}

.events {
    padding: unset !important;
}

.selectedEvents {
    background-color: #124e68 !important;
    color: white !important;
    border-radius: 50% !important;
}

.calenderBox {
    text-align: left;
    background-color: #124e68;
    color: white;
    border-radius: 6px;
    padding: 20px;
}

.ant-spin-dot {
    width: unset !important;
    height: unset !important;
    margin-bottom: 35px;
}

.ant-spin-dot-item {
    width: 20px !important;
    height: 20px !important;
    background-color: #1d749a !important;
}

.spinElement {
    margin-top: 120px !important;
}

.trainCard {
    background: #124e68 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 24px #0000000f;
    border-radius: 12px;
    text-align: center;
}

.trainCard button {
    background: #f0ae00 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 24px #0000000f !important;
    border-radius: 12px;
    border: unset !important;
    width: 100%;
    font-size: 25px;
}

.trainCard p {
    letter-spacing: 1.45px;
    color: #ffffff;
    font-size: 20px;
    padding: 10px;
}

.forumCard {
    background: #124e68 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 24px #0000000f;
    border-radius: 12px;
    text-align: center;
    position: relative;
    /* height: 200px; */
}

.forumCard button {
    /* background: #f0ae00 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 24px #0000000f !important;
    border-radius: 12px;
    border: unset !important;
    width: 100%;
    font-size: 25px; */
    background: unset !important;
    box-shadow: unset !important;
    border-radius: 12px;
    border: unset !important;
    margin-bottom: 10px;
}

.forumCard p {
    letter-spacing: 1.45px;
    color: #ffffff;
    font-size: 15px;
    padding: 10px;
    word-wrap: break-word;
}

.forumCard h5 {
    letter-spacing: 1.45px;
    color: #ffffff;
    font-size: 17px;
    padding: 10px;
    text-align: left;
}

.forumCard a {
    color: white !important;
}

.alignLeft {
    text-align: left;
}

.sender {
    text-align: right;
    /* max-width: 60%; */
    color: white;
}

.sender p {
    background-color: #124e68;
    padding: 10px;
    border-radius: 15px;
    display: inline-block;
}

.chatRow {
    display: contents !important;
    align-items: flex-end;
}

.reciever {
    text-align: left;
}

.reciever p {
    background-color: #bdbbbb;
    padding: 10px;
    display: inline-block;
    border-radius: 15px;
}

.scroll {
    overflow-y: auto;
}

.message-input {
    width: 80% !important;
    border: solid #9c9999 1px !important;
}

/**********************************************/

/* .card1 {
  background-color: #22d2a0;
  padding: 15px;
  margin: 10px;
  border: 3px solid black;
  box-shadow: 20px 18px 0 -3px #929191, 30px 31px 0 -3px #929191,
    40px 49px 0 -3px #929191;
} */

.uploadBtn::-webkit-file-upload-button {
    /* visibility: hidden; */
    /* background-color: red; */
    /* width:400px */
    display: none;
}

.uploadBtn::before {
    content: "Upload Template";
    display: inline-block;
    background: #124e68;
    border: unset;
    /* border-radius: 3px; */
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    /* font-size: 10pt; */
    color: white;
    padding: 7px;
    width: 160px;
    font-size: 14px;
    vertical-align: middle;
    margin-right: 5px;
    text-align: center;
}

.downloadBtn {
    background-color: #124e68 !important;
    color: white !important;
    border: unset !important;
    box-shadow: unset !important;
    height: 37px !important;
}

.ant-select {
    width: 90% !important;
    display: block !important;
}

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
} */
.Toastify__toast--info {
    background: #2d75a5;
}

.Toastify__toast {
    position: relative;
    min-height: 100px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
}

.payment-card {
    box-shadow: 0px 17px 32px #0000002b, 0px 17px 32px #0000002b,
    0px 17px 32px #0000002b, 0px 17px 32px #0000002b;
    padding: 20px;
    border-radius: 10px;
}

.payment-card i {
    font-size: 24px;
    margin-top: 10px;
}

.payment-btn {
    background-color: #cecece;
    border-color: #cecece;
    display: block;
    margin-top: 10px;
}

.exit {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #2d75a5;
    cursor: pointer;
}

.rooms {
    box-shadow: 0px 7px 6px #00000038, 0px 6px 40px #00000038,
    0px -1px 17px #00000038, 0px 0px 5px #00000038;
    padding: 18px;
    max-height: 500px;
}

.roomHead {
    padding: 18px;
    padding: 10px;
}

.error {
    color: red;
}

.forumBtn1,
.forumBtn1:hover {
    background: #124e68 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 24px #0000000f;
    border-radius: unset;
    border: unset !important;
    /* margin-top: 15px; */
    color: white;
}

.courseImg {
    height: 200px;
    width: 300px;
}

.chat {
    cursor: pointer;
}

.selected-chat {
    background: #124e68;
    color: white;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
}

.chat {
    padding: 10px;
    border-radius: 10px;
}

.chat:hover {
    background: #309bc9;
    color: white;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
}

.ant-picker {
    display: block !important;
}

.lectureLink a {
    color: #f0ae00;
}

/****************************************************************/
/*Card Styling*/

/* .forumCard {
  position: relative;
}
.desc-card {
  position: absolute;
  top: 100%;
  left: 0%;
  right: 0%;

  padding: 20px 10px;
  background-color: white;
  display: none;
  z-index: 11111111;
  border: 1px solid #d1d7dc;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
  width: 20rem;
}
.desc-card h5,
.desc-card p {
  color: black;
  text-align: left;
}
.forumCard:hover .desc-card {
  display: block;
} */
textarea {
    display: block;
    width: 100%;
    border: solid 1px #d9d9d9 !important;
}

textarea:focus-visible {
    outline: unset !important;
}

@media only screen and (max-width: 850px) {
    .background {
        padding: unset !important;
    }

    .navbar-brand {
        margin: unset !important;
    }

    .content-card {
        border-radius: unset;
        box-shadow: unset;
    }
}

.studentcalender {
    margin-top: 50px;
    margin-left: 300px;
}

@media only screen and (max-width: 991px) {
    .viewSuper,
    .content-margin {
        margin-left: unset !important;
    }

    .studentcalender {
        margin-top: unset;
        margin-left: unset;
    }
}

.css-6iz4mj-MuiButtonBase-root-MuiIconButton-root {
    background-color: black !important;
    color: blue !important;
}

.ant-alert-info {
    background-color: #124e68 !important;
    border: 1px solid #124e68 !important;
}

.ant-alert-message {
    color: white !important;
}

.imgCol1 {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 700px) {
    .rooms {
        margin-top: 30px;
    }

    .forumBtn1,
    .forumBtn1:hover {
        margin-top: 15px;
    }

    .ant-picker-calendar-header .ant-picker-calendar-month-select {
        margin-left: unset !important;
    }

    .ant-select,
    .ant-picker {
        width: 100% !important;
    }

    .list-group-item {
        padding: unset !important;
    }

    .imgCol1 {
        padding: 15px;
    }
}

.fa-chevron-left {
    cursor: pointer;
}

.ant-select-selection-placeholder {
    /* font-weight: bold !important; */
    color: #9b9b9b !important;
    /* padding-top: 10px !important; */
}

.selectTutor .ant-select .ant-select-selector {
    height: 32px !important;
}

.questionNumberInput {
    width: 55% !important;
}

.table-data-feature i {
    color: #124e68 !important;
    cursor: pointer;
}

/***************************responsive card*********************/
@media (max-width: 1250px) and (min-width: 1100px) {
    .course-card {
        width: 300px;
        height: 500px;
    }

    .card-col {
        max-width: 40.333333% !important;
    }
}

@media (max-width: 1099px) and (min-width: 700px) {
    .course-card {
        width: 300px;
        height: 500px;
    }

    .card-col {
        max-width: 45.333333% !important;
    }
}

.fa-ellipsis-v {
    color: white !important;
    font-size: 15px;
    position: absolute;
    top: 10px;
    right: 15px;
}

.ant-card,
.ant-card-actions {
    background-color: #124e68 !important;
}

.ant-card-meta-title,
.ant-card-meta-description,
.ant-card-actions > li,
.viewForum {
    color: white !important;
}

.ant-card-actions > li:not(:last-child) {
    border-right: unset !important;
}

.ant-card-meta-description {
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    /* width: 500px; */
    /* white-space: nowrap; */
    height: 70px;
    overflow-x: hidden;
    overflow-y: scroll;
}


.ant-card ::-webkit-scrollbar {
    width: 5px;
}

.ant-card ::-webkit-scrollbar-thumb {
    background: #124e68;
}

.ant-card ::-webkit-scrollbar-thumb:hover {
    background: #124e68;
}

.ant-card {
    border-radius: 10px !important;
}

.ant-card-actions {
    border-radius: 0px 10px;
}

.choiceP {
    display: inline-block !important;
    color: gray !important;
    font-size: 18px;
}

.ant-pagination-options {
    display: none !important;
}

.file-upload2 {
    height: 60px;
    /* width: 60px; */
    /* border-radius: 100px; */
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 4px dashed #124e68;
    overflow: hidden;
    background-color: linear-gradient(to bottom, #fff 50%, #ffffff 50%);
    background-size: 100% 200%;
    transition: all 1s;
    color: #124e68;
    font-size: 13px;
}

.file-upload2 input[type="file"] {
    height: 200px;
    /* width: 200px; */
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.file-upload2 :hover {
    background-position: 0 -100%;

    color: #124e68;
}

.viewP {
    color: #124e68;
    cursor: pointer;
    font-size: 15px;
}

.confirmModal {
    padding: 32px;
    font-size: 20px;
}

.confirmModal button {
    margin-inline-start: auto;
}