.header {
  background-color: white;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 85px;
  z-index: 11111;
  /* width: 100%; */
  box-shadow: 0px 17px 32px #0000002b;
}
.header .btn3 {
  background: #238bb8 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 24px #0000000f !important;
  border-radius: 10px;
  border: unset !important;
}
.header .btn1 {
  background: #124e68 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 24px #0000000f !important;
  border-radius: 10px;
  border: unset !important;
  width: 100px;
}

.header .btn2 {
  background: #238bb8 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 24px #0000000f !important;
  border-radius: 10px;
  border: unset !important;
  width: 100px;
}
.btn1 a,
.btn2 a {
  color: white !important;
}

.navIcon {
  font-size: 20px !important;
}

.navBtn,
.navBtn:focus {
  background-color: #ffffff !important;
  border: unset !important;
  color: #939494 !important;
  font-size: 17px;
  box-shadow: unset !important;
}
.navIcon {
  font-size: 30px !important;
}
.navDropDown {
  border: unset !important;
  box-shadow: 0px 17px 32px #00000038;
}
.navDropDown .dropdown-item {
  font-size: 13px;
  color: #707070;
  letter-spacing: 0.65px;
}
.btnsDiv {
  margin-top: 20px;
}
@media (max-width: 700px) {
  .homeLogo {
    width: 130px;
  }
  .header .btn2 {
    width: unset;
  }
  .header .btn1 {
    width: unset;
  }
  .btnsDiv {
    margin-top: unset;
  }
  .header
  {
    padding: 10px 0px;
  }
}
