@font-face {
  font-family: "SF Light";
  src: url("../fonts/FontsFree-Net-SFProDisplay-Light.ttf");
}
@font-face {
  font-family: "SF Medium";
  src: url("../fonts/FontsFree-Net-SFProDisplay-Medium.ttf");
}
@font-face {
  font-family: "SF Semibold";
  src: url("../fonts/FontsFree-Net-SFProDisplay-Semibold.ttf");
}

/* .sidebar-container{
  padding-left: 20px;
  width: 300px;
  height: 80%;
  position: absolute;
} */
.my-menu {
    border-radius: 10px;
    position: absolute;
    width: 245px;
    height: 75%;
    margin-left: 15px;
    top: 144px;
    background-color: #fff;
    z-index: 11;
    padding-left: 30px;
    box-shadow: 0px 17px 32px #00000038 ,0px 17px 32px #00000038,0px 17px 32px #00000038,0px 17px 32px #00000038;
  }

.logo{
  width: 100%;
}
  .side-list {
    list-style-type: none;
    position: relative;
    padding: unset;
    padding-top: 15px;
  }
  .side-list li a {
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    padding: 12px 30px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    line-height: 27px;
    opacity: 0.7;
    text-decoration: none;
    box-shadow: 0 9px 11px 0 rgb(0 0 0 / 21%);
    margin-top: 15px;
    border-radius: 60px 0px 0px 60px;
  }
  .side-list i {
    margin-right: 12px;
  }
  .side-list .navitem-active {
    font-family: "SF Semibold";
    border-radius: 60px 0px 0px 60px;
    color: #2C2731 !important;
    background: linear-gradient(#EAEAEA,#EAEAEA);
    box-shadow: 0 7px 12px 0 rgba(95, 118, 232, 0.21);
    opacity: 1;
  }
  
  .sub-menu{
    padding-inline-start:unset!important;
      width: 220px;
      right: -5%;
      font-size: 16px;
      position: absolute;
      border-radius: 20px;
      box-shadow: 0 7px 12px 0 #00000038, 0 7px 12px 0 #00000038, 0 7px 12px 0 #00000038, 0 7px 12px 0 #00000038;
      transform: scale(0);
      list-style: none;
      transform-origin: top center;
      transition: all 300ms ease-in-out;
  }

  .sub-menu li {
    font-family: "SF Light";
    font-size: 16px;
    font-weight: bold;
    background: white;
    padding: 8px 0;
    color: #2C2731;
    transform: scale(0);
    transform-origin: top center;
    transition: all 300ms ease-in-out;
  }
  .sub-menu li:first-child{
    border-radius: 20px 20px 0px 0px;

  }
  .sub-menu li:last-child {
    border-radius: 0px 0px 20px 20px;
    border-bottom: 0;
  }
  .sub-menu li:hover {
    background: #EAEAEA;
  }

  .side-list>li:hover .sub-menu li {
    transform: scale(1);
  }
  .side-list>li:hover .sub-menu {

    transform: scale(1);
  }
  @media only screen and (max-width: 991px) {
    .my-menu
    {
      display: none;
    }
  }