.wrapper {
  width: 100%;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
}

.file-upload {
  height: 60px;
  width: 60px;
  /* border-radius: 100px; */
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 4px solid #ffffff;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #124e68 50%, #ffffff 50%);
  background-size: 100% 200%;
  transition: all 1s;
  color: #ffffff;
  font-size: 30px;
}
.file-upload input[type="file"] {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-upload :hover {
  background-position: 0 -100%;

  color: #124e68;
}

.materialName,
.materialName a {
  text-decoration: underline;
  color: #000000de !important;
  font-size: 18px;
  cursor: pointer;
}
