@font-face {
  font-family: "Circular";
  src: url("../fonts/shellypalmer-circular-std-medium.ttf");
}

.tutor-form {
  margin-left: 250px;
}

.card-container {
  margin: unset;
  text-align: center;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}
.col-padding {
  padding: 50px 0px 50px 50px;
}
.col-padding2 {
  padding: 30px 0px 20px 70px;
}
.col-padding3 {
  padding-left: 0px;
}
.form {
  height: 80vh;
  text-align: left;
  overflow: scroll;
  overflow-x: hidden;
}

.heading {
  color: #2e384d;
  font-size: 48px;
  margin-bottom: 35px;
}
.sub-header {
  font-size: 25px;
  color: #2e384d;
  display: inline-block;
  cursor: pointer;
}

#dash {
  height: 2px;
  background-color: #124e68;
  width: 30%;
}

.form-label {
  margin: 20px 0px;
  color: #8c98a9;
  font-size: 16px;
}

.form-group {
  margin-bottom: 0px;
}
.form-control {
  width: 90%;
  padding: 0px 20px 0px 20px;
  height: 50px;
  color: #2e384d;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  background-color: #e0e7ff33;
  font-size: 14px;
}

.col-padding3 .form-control {
  width: 75%;
}

.submit-btn {
  background-color: #124e68;
  width: 90%;
  margin-top: 20px;
  font-size: 18px;
  padding: 15px;
  text-align: center;
}

.sign-in {
  text-align: center;
  margin-top: 20px;
  color: #8c98a9;
  font-size: 18px;
}

.sign-in-link {
  color: #2e5bff;
  font-size: 18px;
}

@media only screen and (max-width: 700px) {
  .form-control,
  .col-padding3 .form-control {
    width: 100%;
  }
}
