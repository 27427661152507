@font-face {
  font-family: "SF Light";
  src: url("../fonts/FontsFree-Net-SFProDisplay-Light.ttf");
}
@font-face {
  font-family: "SF Medium";
  src: url("../fonts/FontsFree-Net-SFProDisplay-Medium.ttf");
}
@font-face {
  font-family: "SF Semibold";
  src: url("../fonts/FontsFree-Net-SFProDisplay-Semibold.ttf");
}
.bg-light {
  background-color: #eaeaea !important;
  height: 60px;
}
.navbar-expand-lg .navbar-collapse {
  height: 100%;
}
.navbar-expand-lg .navbar-nav {
  height: 100%;
  padding-top: 10px;
}
.navbar {
  padding: 0px;
  border-radius: 10px;
}
.tabs {
  border-radius: 60px;
  background-color: #eaeaea;
}
.tabs a {
  background-color: white;
  margin-right: 10px;
  font-family: "SF Semibold";
  color: #000000de !important;
  border-radius: 5px 5px 0px 0px;
  width: 150px;
  padding-top: 15px;
}
.Nav {
  background-color: white;
  border-radius: 60px 10px 0px 60px;
}

.Nav .nav-link {
  border-right: 1px solid #eaeaea;
  padding-right: 20px !important;
  height: 40px;
}
.dropdown-toggle::after {
  margin-left: 0.255em;
  font-size: 25px;
}
.navbar-brand {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  z-index: 100;
  margin: 70px 45px 0px 20px;
  box-shadow: 0px 17px 32px #0000002b, 0px 17px 32px #0000002b,
    0px 17px 32px #0000002b, 0px 17px 32px #0000002b;
}

.dropdown-toggle.nav-link {
  padding: 0px 10px;
}

.dropdown-toggle.nav-link span {
  font-size: 25px;
  margin-left: 10px;
}

.far {
  font-size: 25px;
  margin-left: 25px;
}
.logoutGoogle {
  color: black !important;
  background-color: transparent !important;
  box-shadow: unset !important;
  opacity: 1 !important;
}

.notification-bar {
  position: absolute;
  right: 0;
  top: 60px;
  text-align: left;
  width: 400px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px;
  background: #e8e7e7;
  z-index: 10;
}

.spinner {
  position: absolute;
  right: 50%;
  top: 70%;
}
.single-notification {
  padding: 15px 0px;
  border-bottom: 1px solid #afacac;
}
.navList {
  display: none;
}
.navList li {
  list-style-type: none;
}
@media only screen and (max-width: 974px) {
  .bg-light {
    height: unset !important;
  }
  .Nav {
    background-color: unset !important;
  }
  .navbar-brand {
    background-color: unset !important;
    box-shadow: unset !important;
  }
  .navList {
    display: block;
  }
  .tabs a {
    width: unset;
    background-color: unset;
  }
}
