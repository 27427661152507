@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 74%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 77%;
  }
}
.loginPage {
  background-color: #124e68;
  height: 100vh;
}
/* .loginImg {
  height: 75vh;
} */

.img-box {
  box-shadow: 0px 17px 32px #0000006e;
  border-radius: 8px;
  /* margin-bottom: 20px;
    margin-top: 20px; */
}
.title1 {
  /* font: normal normal normal 55px/63px Circular Std Medium; */
  letter-spacing: 0px;
  color: #2e384d;
  margin-bottom: 40px;
}
.loginContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 17px 32px #0000006e;
  border-radius: 8px;
}

.loginPage .loginInput {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e0e7ff;
  width: 90%;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  text-align: left;
  letter-spacing: 0px;
  color: #8c98a9;
  font-size: 16px;
}
.loginBtn1 {
  background-color: #124e68 !important;
  border-radius: 5px !important;
  width: 90%;
  /* padding: 25px !important; */
  height: 60px !important;
  border: 1px solid #124e68 !important;
  /* font-family: "Circular"; */
}

.forget {
  margin-bottom: 15px;
  letter-spacing: 0px;
  color: #8c98a9;
}

.loginBtn2 {
  background-color: #367fc0 !important;
  border-radius: 5px;
  width: 90%;
  /* padding: 25px !important; */
  height: 40px !important;
  border: 1px solid #367fc0 !important;
  color: white;
  /* font-family: "metropolis"; */
}
.loginBtn3 {
  background-color: #dd4b39 !important;
  border-radius: 5px;
  width: 90%;
  /* padding: 25px !important; */
  height: 40px !important;
  border: 1px solid #dd4b39 !important;
  color: white !important;
  text-align: center !important;
  display: unset !important;
  /* font-family: "metropolis" !important; */
}

.loginBtn3::before {
  content: "\f0d5";
  font-family: "Font Awesome 5 Brands" !important;
  margin-right: 40px;
}

.p1 {
  letter-spacing: 0px;
  color: #8c98a9;
  text-align: center !important;
}
.span1 {
  letter-spacing: 0px;
  color: #dd4b39;
}

@font-face {
  font-family: "metropolis";
  src: url("../fonts/Metropolis-Medium.otf");
}
@font-face {
  font-family: "Circular";
  src: url("../fonts/shellypalmer-circular-std-medium.ttf");
}
@media (min-width: 992px) and (max-width: 1199px) {
  .viewSuper {
    margin-left: 266px !important;
  }
}
@media (max-width: 700px) {
  /* .loginImg {
    height: 40vh;
  } */
}
