.questionDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 17px 32px #00000038;
  border-radius: 8px;
}
.questionDiv p {
  color: #124e68;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: unset !important;
}
.questionDiv h5 {
  color: #124e68;
  padding: 25px;
  font-weight: 500;
}
.choices {
  color: #124e68;
  font-weight: 500;
}
.choices .ant-radio-inner {
  background-color: #bac4dd !important;
  width: 20px;
  height: 20px;
}

.choices .ant-radio-inner::after {
  top: 4px;
  left: 4px;
  display: block;
  width: 10px;
  height: 10px;
  background: transparent linear-gradient(90deg, #edb82b 0%, #e69222 100%) 0% 0%
    no-repeat padding-box;
}
.quesBtn1 {
  background: #124e68 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0000000f;
  border-radius: 12px;
  width: 105px;
  height: 50px;
  font-size: 20px !important;
  margin-right: 15px;
  border: unset !important;
}
.quesBtn2 {
  background: #f0ae00 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0000000f;
  border-radius: 12px;
  width: 105px;
  height: 50px;
  font-size: 20px !important;
  margin-right: 15px;
  border: unset !important;
}
.forumBtn1 {
  background: #124e68 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 24px #0000000f;
  border-radius: unset;
  border: unset !important;
  /* padding: 10px; */
  letter-spacing: 1px;
  font-weight: bold;
  /* margin-top: 15px; */
  color: white;
}

.forumBtn1:hover {
  background: #0a2a38 0% 0% no-repeat padding-box !important;
}
.action-btn {
  background: rgb(29, 158, 29) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 24px #0000000f;
  border-radius: unset;
  font-weight: bold;
  border: unset !important;
  /* margin-top: 15px; */
  color: white;
}
.forumBtn1 a {
  color: white !important;
}

.answersDiv {
  width: 90%;
  border: solid #124e68;
  border-radius: 12px;
  margin-top: 40px;
}
.ant-collapse > .ant-collapse-item {
  border: unset !important;
}
.questionDiv2 {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 17px 32px #00000038;
  border-radius: 8px;
  width: 80%;
  padding: 30px;
  margin-left: 40px;
  color: #124e68 !important;
  border: unset !important;
  text-align: left;
  font-size: 20px;
  position: relative;
}

.replies {
  padding-left: 25px;
}
.questionDiv2 .fa-sort-up,
.questionDiv2 .fa-sort-down {
  position: absolute;
  right: 15px;
}
.replyBtn {
  background-color: unset !important;
  color: grey !important;
  font-size: 13px;
  border: unset !important;
  box-shadow: unset !important;
}
.repliesCard {
  padding-left: 70px !important;
  position: relative;
  width: 60%;
}
.repliesCard .fa-trash {
  position: absolute;
  right: 15px;
  color: #124e68;
}

.questInput {
  width: 80%;
}
.editIcon {
  font-size: 20px;
}
.explaination {
  margin-left: 20px;
  cursor: pointer;
  color: #a1a2a3;
  font-weight: bold;
}
.questionImg {
  width: 500px;
  height: 500px;
}

@media only screen and (max-width: 700px) {
  .questionDiv2 {
    width: 100%;
    margin-left: unset;
    padding: 20px;
  }
  .repliesCard {
    width: unset;
    padding-left: unset !important;
  }
  .questionImg {
    width: unset;
    height: unset;
  }
}
