@font-face {
    font-family: "SF Light";
    src: url("../fonts/FontsFree-Net-SFProDisplay-Light.ttf");
  }
  @font-face {
    font-family: "SF Medium";
    src: url("../fonts/FontsFree-Net-SFProDisplay-Medium.ttf");
  }
  @font-face {
    font-family: "SF Semibold";
    src: url("../fonts/FontsFree-Net-SFProDisplay-Semibold.ttf");
  }
.content-margin{
    margin-left: 280px;
    margin-top: 80px;
    text-align: left;
    padding-bottom: 50px;
}

.course-title{
    font-family: "SF Semibold";
    font-size: 32px;
    text-align: left;
}

.course-description{
    font-family: "SF Light";
    text-align: left;
    font-size: 19px;
}
.course-details-keys ,.course-info{
    font-family: "SF Light";
    color: #00000099;
    text-align: left;
}
.pic{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
}
.instructor{
    display: inline-block;
    margin-left: 10px;
    font-family: "SF Semibold";
    color: black;
}
.instructor,.course-info{
    font-size: 13px;
}
.sub-title{
    margin: 20px 0px;
    font-size: 20px;
    font-family: "SF Semibold";
}

.list-group-item{
    border: unset;
    margin-bottom: 20px;
    box-shadow: 0px 17px 32px #00000038;
}

.list-group-item .toggler{
    width: 100%;
}
.toggler{
    background-color: #124E68;
    border-color:#124E68 ;
    padding: 20px 10px;
    border-radius: 10px;
    text-align: right;
    font-size: 16px;color: #FFFFFF99;
    font-family: "SF Medium";
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
/* .react-switch-bg{
    position: absolute!important;
    top: -12px;
    left: 20px;
}
.react-switch-handle{
    top: -11px!important;
    left: 20px;
} */

.nameSwitch{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.nameSwitch p{
    margin-bottom: 0px !important;
}
.toggler i{
    /* margin-left: 50px; */
}

.toggler:hover{
    background-color: #124E68;
    border-color:#124E68 ;
}

.toggler:focus{
    background-color: #124E68;
    border: none;
    outline: #124E68;
}
.details{
    margin-top: 10px;
    font-family: "SF Semibold";
}

.details p{
    font-family: "SF Light";
}
.view-more-btn{
    /* position: absolute;
    bottom: 0px; */
    padding: 10px 10px;
    margin-left: 10px;
    border: none;
    font-size: 18px;
    border-radius: 15px;
    margin-bottom: 20px;
    background-color: #124E68 !important;
}
.view-more-btn a{
    color: white !important;

}
