.homePage {
  background-color: #124e68;
  position: relative;
  /* overflow-x: hidden; */
}
.homeContainer {
  background-image: url("../images/bg.png");
  background-color: #ffff;
  /* box-shadow: 0px 17px 32px #0000006e; */
  border-radius: 8px;
  padding-right: unset !important;
  padding-left: unset !important;
  background-repeat: no-repeat;
}
.home-carousel .carousel-caption {
  bottom: 30% !important;
}
.home-carousel h1 {
  text-align: left;
  letter-spacing: 0px;
  color: #263238;
  font-size: 25px;
}
.home-carousel p {
  text-align: left;
  letter-spacing: 0px;
  color: #263238;
}

.carouselTitles{
  /* display: flex; */
  align-items: center;
}

.home-carousel button {
  background-color: #295f76 !important;
  color: white;
  border-radius: 6px;
  border: solid #295f76 !important;
  font-size: 13px;
  height: 28px;
  padding: unset;
  width: 100px;
}
.carousel-control-prev-icon {
  background-image: url("../images/prev.PNG");
}
.carousel-control-next-icon {
  background-image: url("../images/next.PNG");
}
.addCourseBtn {
  position: absolute;
  left: 0px;
  border: 1px solid #f0ae00;
  bottom: 0px;
  font-size: 24px;
  padding: 20px 0px;
  width: 100%;
  background-color: #f0ae00;
  border-radius: 10px;
}

.addCourseBtn a {
  color: white;
}
.addCourseBtn a:hover {
  color: white;
}
.addCourseBtn:hover {
  background-color: #c58f05;
  border: 1px solid #c58f05;
}
.home-btn2 {
  background-color: white !important;
  border: white !important;
  color: #114b64 !important;
  font-weight: bold !important;
  font-size: 28px;
  box-shadow: 0px 17px 32px #0000002b;
  border-radius: 8px;
}
.home-courses {
  text-align: -webkit-center;
}
.home-courses .card-body {
  padding: 20px 0px;
}
.home-courses .card {
  position: relative;
  border: unset !important;
}
.overlay {
  background-color: #124e68;
  position: absolute;
  top: 37%;
  color: white;
  font-weight: bold;
  right: -4%;
  width: 130px;
  height: 32px;
  box-shadow: 0px 17px 32px #0000002b;
  border-radius: 5px;
  font-size: 19px;
}
.overlay2 {
  background-color: #ffff;
  position: absolute;
  top: 5%;
  /* color: white; */
  font-weight: bold;
  left: 3%;
  width: 85px;
  height: 27px;
  box-shadow: 0px 17px 32px #0000002b;
  border-radius: 5px;
  font-size: 15px;
}
.overlay2 a,
.overlay2 a {
  color: #f0ae00 !important;
  /* padding: 15px; */
}
.overlay3 {
  background-color: #ffff;
  position: absolute;
  top: 15%;
  color: #f0ae00;
  font-weight: bold;
  left: 3%;
  width: 85px;
  height: 27px;
  box-shadow: 0px 17px 32px #0000002b;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}
.home-footer {
  background-color: #124e68;
  /* height: 50vh; */

  box-shadow: 0px 17px 32px #0000002b;
  /* width: 120%; */
  /* position: absolute;
    left: 0%;
    right:0%;
    bottom: 10px;
    z-index: 11111; */
}

.footer-list {
  list-style-type: none;
  color: #ffffff;
}
.footer-list li {
  padding-bottom: 10px;
}
.footer-list li a {
  color: #ffffff !important;
}
.listTitle {
  font-size: 20px;
  color: #ffffff !important;
  margin-bottom: 10px;
  font-weight: 500;
}

.home-footer h6 {
  color: white;
  font-size: 20px;
  margin-top: 30px;
}

.home-footer .form-control {
  background-color: #ebe9e9 !important;
}
.home-footer .input-group-text {
  background-color: #ebe9e9 !important;
  cursor: pointer;
}
.cpy {
  font-weight: bold;
  font-size: 14px;
  color: #124e68;
}
.footer-p1 {
  color: white;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 0rem !important;
}
.footer-p2 {
  color: white;
  font-weight: bold;
}

.footerDiv {
  box-shadow: 0px 17px 32px #0000002b;
}

.card-img-top {
  height: 200px !important;
}
.paddingLeft {
  padding-left: 25px;
}

@media (max-width: 700px) {
  .home-carousel .carousel-caption {
    bottom: 0% !important;
  }
  /* .carouselTitles {
    display: none !important;
  } */
  .carouselImg {
    height: 90px;
  }
  .paddingLeft {
    padding-left: unset;
  }
}
.enrollBtn,
.viewBtn,

.enrollBtn a {
  background-color: #fff !important;
  color: #c58f05 !important;
  box-shadow: 0px 1px 32px #0000002b;
  border: unset !important;

}

.viewBtn {
  color: #114b64 !important;
} 

.courseDesc {
  height: 80px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.courseDesc::-webkit-scrollbar {
  width: 5px;
}

.courseDesc::-webkit-scrollbar-thumb {
  background: #124e68; 
}

.courseDesc::-webkit-scrollbar-thumb:hover {
  background: #124e68; 
}

@media (max-width: 1500px) {
  .home-carousel .carousel-caption {
    bottom: 25% !important;
  }
  .home-carousel h1 {
    font-size: 25px !important;
  }
}
@media (max-width: 575px){
  .home-carousel .carousel-caption {
    bottom: 0% !important;
}
.home-carousel h1 {
  font-size: 16px !important;
  text-align: center;
}
.home-carousel p{
  display: none;
}
}
