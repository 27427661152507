.submit-btn {
  text-align: right;
}

.courseBtn1 {
  background-color: #124e68 !important;
  border: unset !important;
  width: 115px;
}

.rdt_TableCol {
  font-size: 18px !important;
}
.rdt_Table {
  border: solid 1px #bcbebd;
}
.rdt_TableCell,
.rdt_TableCol {
  border-left: solid 1px #bcbebd;
}
.rdt_TableHeadRow {
  border-bottom: solid 1px #bcbebd;
}
.table-data-feature,
.rdt_TableCol_Sortable {
  padding-left: 15px;
  display: flex;
}
.course-card {
  box-shadow: 0px 17px 32px #0000002b, 0px 17px 32px #0000002b,
    0px 17px 32px #0000002b, 0px 17px 32px #0000002b;
  padding: 20px;
  border-radius: 10px;
  height: 570px;
}
.homeCoursesBtn {
  margin-left: 25px;
}
@media only screen and (max-width: 700px) {
  .tutor-form {
    margin-left: unset !important;
  }
  .col-padding2 {
    padding: 30px 0px 20px 0px !important;
  }
  .homeCoursesBtn {
    margin-left: 15px;
  }
}
