@font-face {
    font-family: "Circular";
    src: url("../fonts/shellypalmer-circular-std-medium.ttf");
}

.registerPage {
    background-color: #124e68;
    height: 100vh;
  }
  .registerContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 17px 32px #0000006e;
    border-radius: 8px;
  }
  .img-box {
    box-shadow: 0px 17px 32px #0000006e;
    border-radius: 8px;
    /* margin-bottom: 20px;
    margin-top: 20px; */
}
/* .loginImg {
    height: 75vh;
  } */
.avec{
    width: 100%;
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.2), 0 8px 40px 0 rgba(0, 0, 0, 0.2), 0 8px 40px 0 rgba(0, 0, 0, 0.2), 0 8px 40px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    max-height: 735px;
}

.card-container{
    margin: unset;
    text-align: center;
}
.row{
    margin-right: 0px;
    margin-left: 0px;
}
.col-padding{
    padding:50px 0px 50px 50px;
}
.col-padding2{
    padding:30px 0px 20px 70px;
}
.col-padding3{
    padding-left: 0px;
}
.form{
    height: 80vh;
    text-align: left;
    overflow: scroll;
    overflow-x: hidden;
}




.heading{
    color: #2E384D;
    font-size: 48px;
    margin-bottom: 35px;
}
.sub-header{
    font-size: 25px;
    color: #2E384D;
    display: inline-block;
    cursor: pointer;
}

#dash{
    height: 2px;
    background-color: #124E68;
    width: 30%;
}

.form-label{
    margin: 20px 0px;
    color: #8C98A9;
    font-size: 16px;
}

.form-group{
    margin-bottom: 0px;
}
.form-control{
    width: 90%;
    padding: 0px 20px 0px 20px;
    height: 50px;
    color: #2E384D;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    background-color: #E0E7FF33;
    font-size: 14px;
}

.col-padding3 .form-control{
    width: 75%;
}
.add-study{
    background-color: #124E68;
    width: 90%;
    margin-top: 20px;
    font-size: 18px;
    padding : 15px;
}
.submit-btn{
    background-color: #124E68;
    width: 90%;
    margin-top: 20px;
    font-size: 18px;
    padding : 15px;
}

.sign-in{
    text-align: center;
    margin-top: 20px;
    color: #8C98A9;
    font-size: 18px;
}

.sign-in-link{
    color :#2E5BFF;
    font-size: 18px;
}
@media (max-width: 700px) {
    /* .loginImg {
      height: 40vh;
    } */
  }